<div id="portfolio">
    <!-- ========================= header start ========================= -->
  <header class="header">
    <div class="navbar-area sticky">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <!-- Navegación -->
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand" href="index.html">
                <img src="../../../assets/logosis.png" alt="Logo" />
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
              </button>

              <div
                class="collapse navbar-collapse sub-menu-bar"
                id="navbarSupportedContent"
              >
                <div class="ms-auto">
                  <ul id="nav" class="navbar-nav ms-auto">
                    <li class="nav-item">
                      <a class="page-scroll active" href="#home">Inicio</a>
                    </li>
                    <li class="nav-item">
                      <a class="page-scroll" href="#about">Nosotros</a>
                    </li>
                    <li class="nav-item">
                      <a class="page-scroll" href="#features">Servicios</a>
                    </li>
                    <li class="nav-item">
                      <a class="page-scroll" href="#products">Productos</a>
                    </li>
                    <li class="nav-item">
                      <a class="page-scroll" href="#contact">Contacto</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="header-btn">
                <a href="sisproteck-application" class="main-btn btn-hover">Aplicación</a>
              </div>
              <!-- navbar collapse -->
            </nav>
            <!-- navbar -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>
    <!-- navbar area -->
  </header>
  <!-- ========================= header end ========================= -->
    <h3>Escoge tu Estilo</h3>
    <div class="container-fluid p-0 mt-70">
        <h4 class="p-4 section-central">Escoge tu Estilo</h4>
        <div class="row g-0">
            <div class="col-lg-4 col-sm-6 p-4" *ngFor="let desig of designs;">
                <a class="portfolio-box" target="_blank" href="{{desig.image}}" title="Buzos">
                    <img style="max-height: 350px; max-width: 350px;" class="img-fluid" target="_blank" src="{{desig.image}}" alt="..." />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50">Diseño</div>
                        <div class="project-name">Personalizado</div>
                    </div>
                </a>
            </div>
            <!--<div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="assets/img/portfolio/fullsize/4.jpg" title="Project Name">
                    <img class="img-fluid" src="assets/img/portfolio/thumbnails/4.jpg" alt="..." />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50">Category</div>
                        <div class="project-name">Project Name</div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="assets/img/portfolio/fullsize/5.jpg" title="Project Name">
                    <img class="img-fluid" src="assets/img/portfolio/thumbnails/5.jpg" alt="..." />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50">Category</div>
                        <div class="project-name">Project Name</div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="assets/img/portfolio/fullsize/6.jpg" title="Project Name">
                    <img class="img-fluid" src="assets/img/portfolio/thumbnails/6.jpg" alt="..." />
                    <div class="portfolio-box-caption p-3">
                        <div class="project-category text-white-50">Category</div>
                        <div class="project-name">Project Name</div>
                    </div>
                </a>
            </div>-->
        </div>
    </div>
</div>