import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, OnDestroy {

  constructor() {
    localStorage.clear();
  }

  ngOnInit(): void {
    //window.document.getElementById('modals').click();
    this.initSmoothScroll();
    this.initSmoothScroll();
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (event: any): void => {
    // Here scroll is a variable holding the anonymous function 
    // this allows scroll to be assigned to the event during onInit
    // and removed onDestroy
    // To see what changed:
    const scrollY = event.srcElement.scrollTop;
    // Pasa scrollY como parámetro a las funciones
    this.toggleStickyNavbar(scrollY);
    this.toggleBackToTopButton(scrollY);
    this.updateActiveMenuLink(scrollY);
  // this.initScrollToTop(scrollY);

  };

  // Smooth scrolling for menu links
  private initSmoothScroll(): void {
    const pageLinks = document.querySelectorAll('.page-scroll');
    pageLinks.forEach(link => {
      link.addEventListener('click', (event) => {
        event.preventDefault();
        const targetId = link.getAttribute('href');
        if (targetId) {
          document.querySelector(targetId)?.scrollIntoView({
            behavior: 'smooth'
          });
        }
      });
    });
  }

  // Scroll-to-top functionality
  private initScrollToTop(scrollY: number): void {
    const scrollTopBtn = document.querySelector('.scroll-top');
    if (scrollTopBtn) {
      scrollTopBtn.addEventListener('click', () => {
        this.scrollTo(scrollY);
      });
    }
  }

  scrollTo( to: number = 0, duration: number = 1000): void {
    const start = window.scrollY;
    console.log(start)
    const change = to - start;
    const increment = 20;
    let currentTime = 0;
    
    const animateScroll = () => {
      currentTime += increment;
      const val = this.easeInOutQuad(currentTime, start, change, duration);
      console.log(val)
      scrollY = val;
      window.scrollTo(0, val); // Mueve el scroll de la ventana a la nueva posición
      
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      } else {
        // Asegúrate de que al final del desplazamiento, el scroll esté en 0
        window.scrollTo(0, 0);
      }
    };
    
    animateScroll();
  }

  private easeInOutQuad(t: number, b: number, c: number, d: number): number {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }


  private toggleStickyNavbar(scrollY: number): void {
    const headerNavbar = document.querySelector('.navbar-area');
    if (headerNavbar) {
      const sticky = (headerNavbar as HTMLElement).offsetTop;
      if (scrollY > sticky) {
        headerNavbar.classList.add('sticky');
      } else {
        headerNavbar.classList.remove('sticky');
      }
    }
  }

  private toggleBackToTopButton(scrollY: number): void {
    const backToTop = document.querySelector('.scroll-top');
    if (backToTop) {
      if (scrollY > 50) {
        (backToTop as HTMLElement).style.display = 'flex';
      } else {
        (backToTop as HTMLElement).style.display = 'none';
      }
    }
  }

  private updateActiveMenuLink(scrollY: number): void {
    const sections = document.querySelectorAll('.page-scroll');
    sections.forEach(currLink => {
      const val = currLink.getAttribute('href');
      const refElement = document.querySelector(val || '');
      if (refElement) {
        const scrollTopMinus = scrollY + 73; // Ajusta según sea necesario
        if ((refElement as HTMLElement).offsetTop <= scrollTopMinus &&
          (refElement as HTMLElement).offsetTop + (refElement as HTMLElement).offsetHeight > scrollTopMinus) {
          document.querySelector('.page-scroll.active')?.classList.remove('active');
          currLink.classList.add('active');
        } else {
          currLink.classList.remove('active');
        }
      }
    });
  }
}

